export const Config = {
  BaseUrl: "https://lobster-app-gpfv5.ondigitalocean.app/api",
  UploadUrl: "https://lobster-app-gpfv5.ondigitalocean.app",
  // BaseUrl: "https://seashell-app-vsnmp.ondigitalocean.app/api",
  // UploadUrl: "https://seashell-app-vsnmp.ondigitalocean.app",
  audioUrl: "https://shaivamfiles.fra1.cdn.digitaloceanspaces.com",
  API_KEY: "AIzaSyDN_FI3pGwhrIP82TvEHSj7BhVFxJf7J4Q",
};

// export const geoLocationConfig= () => {
//   const data = JSON.stringify({
//     considerIp: true
//   });
//   const config = {
//     method: 'post',
//     url: 'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDN_FI3pGwhrIP82TvEHSj7BhVFxJf7J4Q',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     data: data
//   };
//   return config
// }

export const geoLocationConfig = () => {
  const config = {
    method: "get",
    url: "https://pro.ip-api.com/json?key=t9TIYGF22lITyyv",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return config;
};
